import * as React from "react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import data from "../../data/illustrations.json";

const IllustrationModal = (props) => {
    const { isOpen, onClose } = props;

    const { illustrationData } = data;

    const [searchQuery, setSearchQuery] = React.useState("");
    const [filteredIllustrations, setFilteredIllustrations] = React.useState(illustrationData.illustrations);

    React.useEffect(() => {
        setFilteredIllustrations(illustrationData.illustrations.filter((illustration) => (
            illustration.title.toLowerCase().includes(searchQuery))
        ));
    }, [searchQuery]);

    const onFilter = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    return (
        <Modal
            show={isOpen}
            onHide={onClose}
            backdrop={true}
            keyboard={false}
            centered={true}
            size="xl"
            fullscreen="sm-down"
        >
            <Modal.Header closeButton>
                <Modal.Title>Illustraties</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                            <div className="contact-form-box filter-form-box">
                                <div className="input-group">
                                    <input
                                        name="filter"
                                        className="filter"
                                        id="filter"
                                        type="text"
                                        placeholder="Type een zoekterm .."
                                        value={searchQuery}
                                        onChange={onFilter}
                                    />
                                    <button className="button button__primary">
                                        <span>Filter</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-4 mb-lg-0 d-flex flex-wrap justify-content-center">
                            {filteredIllustrations.map((data, index) => (
                                <div className="p-40px m-10px d-flex flex-column justify-content-center text-center illustration-wrapper" key={index}>
                                    <img
                                        className="chapter-img"
                                        src={data.image}
                                        alt={data.title}
                                        height={300}
                                    />
                                    <span className="m-15px-t">{data.title}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button variant="primary">Understood</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default IllustrationModal;
