/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React  from "react";
import data from "../data/about.json";

const AboutV2 = ({ isBg }) => {

  const { aboutv3 } = data;

  return (
    <section id="aboutV2" className={`section-padding aboutv3 ${isBg === "yes" ? "bg-one": "" }`}>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="author-image">
              <img className="img-fluid" src={aboutv3.image} alt="" />
            </div>
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="aboutv3__content">
              <div className="aboutv3__content--badge" style={{ color: "#c27b7f", fontWeight: 600, textTransform: "uppercase" }}>
                {aboutv3.subtitle}
              </div>
              <h3 className="display-5 mb-0">{aboutv3.title}</h3>
              <p className="m-30px-b text-muted fs-5">{aboutv3.text}</p>
              {aboutv3.description.map((description, index) => <p key={index} className="m-30px-b">{description}</p>)}
              <ul className="social-icon mt-0 mb-0">
                {aboutv3.social?.map((data, index) => (
                  <li key={index}>
                    {data.link === "" ? (
                      ""
                    ) : (
                      <a href={data.link}>
                        <img
                          className="img-fluid"
                          src={data.icon}
                          alt="icon"
                          width="25"
                          height="25"
                        />
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutV2;
