import React from "react";
import Contact from "../contact";
import Footer from "../global/footer";
import Header from "../global/header";
import Hero from "../hero";
import AboutV1 from "../aboutV1";
import AboutV2 from "../aboutV2";
import AboutV3 from "../aboutV3";
import Pricing from "../pricing";
import Testimonial from "../testimonial";
import IllustrationPreview from "../illustrationPreview";
import { useLocation } from "react-router-dom";

const Homepage = ({ header, footer }) => {
  const { menu } = footer;

  const location = useLocation();

  return (
    <React.Fragment>
        {!location.hash && <Header header={header} />}
        <Hero isBg="yes" />
        <AboutV1 isBg="" />
        <AboutV3 isBg="yes" />
        <AboutV2 isBg="" />
        <IllustrationPreview isBg="yes" />
        <Testimonial isBg="" />
        <Pricing isBg="yes" />
        <Contact isBg="" />
        <Footer  isBg="yes" menu={menu} />
    </React.Fragment>
  );
}

export default Homepage;
