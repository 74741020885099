import * as React from "react";
import ProductTile from "./ProductTile";

const ProductList = (props) => {

    const { isBg } = props;

    return (
        <section id="productList" className={`section-padding productList ${isBg === "yes" ? "bg-one": "" }`} style={{ paddingTop: 200 }}>
            <div className="container">
                <div className="row">
                    <div className="snipcart-summary">
                        Number of items: <span className="snipcart-items-count"></span>
                        <br/>
                        Total price: <span className="snipcart-total-price"></span>
                    </div>
                </div>
                <div className="row">
                    <ProductTile
                        id={1}
                        name="Ik & iedereen"
                        price={24.95}
                        imageUrl="assets/images/homepage-hero-image.jpg"
                        description="lorem ipsum description"
                        url="/api/products/book"
                    />
                </div>
            </div>
        </section>
    )
};

export default ProductList;
