/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React  from "react";
import data from "../data/about.json";

const AboutV1 = ({ isBg }) => {

  const { aboutv1 } = data;

  return (
    <section
      id="aboutv1"
      className={`section-padding authorv2 ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{aboutv1.subtitle}</span>
              <h2 className="display-6">{aboutv1.title}</h2>
              <div className="section-divider divider-traingle" />
            </div>
          </div>
        </div>
        <div className="row gx-5">
          <div
              className="col-lg-6 mb-3 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="achieve__image">
                  <img
                      className="img-fluid"
                      src={aboutv1.image}
                      alt="Achive Image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            {aboutv1.description.map((description, index) => <p key={index}>{description}</p>)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutV1;
