
import React from "react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import data from "../data/illustrations.json";

const IllustrationContainer = ({ isBg }) => {
  const { illustrationData } = data;

  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredIllustrations, setFilteredIllustrations] = React.useState(illustrationData.illustrations);

    React.useEffect(() => {
        setFilteredIllustrations(illustrationData.illustrations.filter((illustration) => (
            illustration.title.toLowerCase().includes(searchQuery))
        ));
    }, [searchQuery]);

  const onFilter = (event) => {
      setSearchQuery(event.target.value.toLowerCase());
  };

  return (
      <React.Fragment>
        <section
            id="illustrations"
            className={`hero__padding chapter-preview ${
                isBg === "yes" ? "bg-one" : ""
            }`}
        >
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                        <div className="section-title-center text-center">
                            <span>{illustrationData.title}</span>
                            <h2 className="display-6">{illustrationData.subtitle}</h2>
                            <div className="section-divider divider-traingle" />
                        </div>
                        <span className="text-center">{illustrationData.introText}</span>
                    </div>
                </div>
            </div>
        </section>
          <section
              id="illustrations"
              className={`section-padding chapter-preview`}
          >
              <div className="container">
                  <div className="row">
                      <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                          <div className="contact-form-box filter-form-box">
                              <div className="input-group">
                                  <input
                                      name="filter"
                                      className="filter"
                                      id="filter"
                                      type="text"
                                      placeholder=". . ."
                                      value={searchQuery}
                                      onChange={onFilter}
                                  />
                                  <button className="button button__primary">
                                      <span>Filter</span>
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        <section
            id="illustrations"
            className={`section-padding chapter-preview ${
                isBg === "yes" ? "bg-one" : ""
            }`}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mb-4 mb-lg-0 d-flex flex-wrap justify-content-center">
                        {filteredIllustrations.map((data, index) => (
                            <div className="p-40px m-10px d-flex flex-column justify-content-center text-center illustration-wrapper" key={index}>
                                <img
                                    className="chapter-img"
                                    src={data.image}
                                    alt={data.title}
                                    height={300}
                                />
                                <span className="m-15px-t">{data.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
  );
};

export default IllustrationContainer;
