import React from "react";
import Footer from "../global/footer";
import Header from "../global/header";
import ProductList from "../shop/ProductList";

const ProductPage = ({ header, footer }, props) => {
  const { menu } = footer;

  return (
    <React.Fragment>
        <Header header={header} />
        <ProductList isBg="" />
        <Footer  isBg="yes" menu={menu} />
    </React.Fragment>
  );
}

export default ProductPage;
