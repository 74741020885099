/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import data from "../data/pricing.json";

const Pricing = ({ isBg }) => {
  const { pricing } = data;

  return (
    <section
      id="product"
      className={`section-padding pricing ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{pricing.subtitle}</span>
              <h2 className="display-6">{pricing.title}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          {pricing.pricingItem?.map((data, i) => (
            <div
              key={i}
              className="col-md-6 col-lg-4 mb-4 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={(i + 2) * 50}
            >
              {data.card === "active" ? (
                <div className="pricing__item translateEffect1 active">
                  <img
                      width="100%"
                      src={data.image}
                      alt={data.title}
                      className={"image-product"}
                  />
                  <h3 className="pricing__title">{data.title}</h3>
                  <h3 className="pricing__price">€{data.price}</h3>
                  <button
                      className="button button__primary snipcart-add-item"
                      data-item-id={data.id}
                      data-item-image={data.image}
                      data-item-name={data.title}
                      data-item-description={data.description}
                      data-item-url="/"
                      data-item-price={data.price}
                      data-item-weight={372}
                  >
                    <span>{data.btnText}</span>
                  </button>
                </div>
              ) : (
                <div className="pricing__item translateEffect1">
                  <h3 className="pricing__title">{data.title}</h3>
                  <h3 className="pricing__price">{data.price}</h3>
                  <ul className="pricing__list">
                    {data.feature?.map((items, i) => (
                      <li key={i}>{items.item}</li>
                    ))}
                  </ul>
                  <a href="#" className="btn__secondary">
                    <span>{data.btnText}</span>
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
