import { default as React } from "react";
import { Navbar } from "react-bootstrap";
import { Link as PageLink } from "react-router-dom";
import { Link } from "react-scroll";
import { siteLogo } from "../../global";

const Header = ({ header }) => {

  const [fix, setFix] = React.useState(false);

  function setFixed() {
    if (window.scrollY >= 100) {
      setFix(true);
    } else {
      setFix(false);
    }
  }

  window.addEventListener("scroll", setFixed);

  return (
    <header className={fix ? "header navbar_fixed" : "header"}>
      <div className="container">
        <div className="row">
          <Navbar bg="none" expand="lg">
            <a className="navbar-brand" href="/">
              <img src={siteLogo.logo} alt={siteLogo.alt} height={50} />
            </a>
            <Navbar.Toggle aria-controls="navbarSupportedContent">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarSupportedContent">
              <ul className="navbar-nav menu ms-lg-auto">
                {header.menu?.map((data, i) =>
                  data.external ? (
                    <li className="nav-item" key={i}>
                      <PageLink
                          className={`benefits nav-link ${data.isSpecial && "special"}`}
                          to={{ pathname: `${data.link}`, state: { section: data.section }}}
                      >
                        {data.title}
                      </PageLink>
                    </li>
                  ) : (
                    <li className="nav-item" key={i}>
                      <Link
                        activeClass="active"
                        className={`benefits nav-link ${data.isSpecial && "special"}`}
                        to={`${data.link}`}
                        spy={true}
                        isDynamic={false}
                        hashSpy={false}
                        spyThrottle={500}
                        smooth={true}
                        duration={500}
                        offset={-55}
                      >
                        {data.title}
                      </Link>
                    </li>
                  )
                )}

                <li className="nav-item">
                  <button className="snipcart-checkout nav-link" style={{ marginLeft: 20 }}>
                    <img
                        className="img-fluid"
                        src="assets/icons/cart-alt.svg"
                        alt="icon"
                        width="20"
                        height="20"
                    />
                  </button>
                  <div className="chip">
                    <span className="snipcart-items-count"></span>
                  </div>
                </li>
              </ul>

            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </header>
  );
};

export default Header;
