import React from "react";
import Footer from "../global/footer";
import Header from "../global/header";
import IllustrationContainer from "../illustrationContainer";

const IllustrationPage = ({header, footer}) => {
  const { menu } = footer;

  return (
    <React.Fragment>
     <Header header={header} />
     <IllustrationContainer isBg="yes" />
     <Footer  isBg="" menu={menu} />
    </React.Fragment>
  );
}

export default IllustrationPage;
